<template lang="pug">
  v-container
    v-row(
      justify="center"
    )
      v-col(
        cols="12"
        md="8"
        lg="6"
      )
        h1.text-h4.mb-4 {{ verbage }} User
          span(v-if="!isGetShopPending") &nbsp;to
            v-chip.ml-3.mt-n2
              v-icon.mr-2 mdi-office-building-outline
              | {{ shop.name }}
          span(v-if="!isGetRegionPending") &nbsp;to
            v-chip.ml-3.mt-n2
              v-icon.mr-2 mdi-map
              | {{ region.name }}
        v-card(
          outlined
        )
          v-card-text
            FeathersVuexFormWrapper(
              :item="item"
            )
              template(v-slot="{ clone, save, reset, isDirty }")
                v-form(
                  @submit.prevent="save().then(handleSaveReponse)"
                  ref="form"
                  v-model="valid"
                )
                  v-row
                    v-col
                      v-text-field(
                        label="First Name"
                        filled
                        v-model.trim="clone.firstName"
                        :rules="[rules.required]"
                      )
                    v-col
                      v-text-field(
                        label="Last Name"
                        filled
                        v-model.trim="clone.lastName"
                        :rules="[rules.required]"
                      )
                  v-row
                    v-col
                      v-text-field(
                        label="Username"
                        filled
                        v-model.trim="clone.username"
                        :rules="[rules.required]"
                      )
                  v-row(
                    v-if="isNew || currentUser.isAdmin || currentUser.isRegionAdmin || currentUser.isShopAdmin"
                  )
                    v-col
                      v-text-field(
                        :label="isNew ? 'Password' : 'Update Password'"
                        :type="isEdit ? 'text' : 'password'"
                        filled
                        v-model="clone.password"
                        :rules="isNew ? [rules.required] : []"
                      )
                  v-row
                    v-col
                      v-text-field(
                        label="Email"
                        filled
                        type="email"
                        v-model.trim="clone.email"
                        :rules="[rules.email]"
                        hint="Optional"
                        persistent-hint
                      )
                  v-row
                    v-col
                      v-text-field(
                        label="Phone Number"
                        filled
                        type="tel"
                        v-model.trim="clone.phone"
                        hint="Optional"
                        persistent-hint
                      )

                  v-row
                    v-col
                      v-select(
                        label="Role"
                        filled
                        v-model="clone.permissions"
                        :rules="[rules.required]"
                        :items="availableRoles"
                      )
                  v-row(
                    v-if="currentUser.isAdmin && clone.permissions === 'MSO'"
                  )
                    v-col
                      MsosAutocomplete(
                        :rules="[rules.required]"
                        v-model="clone.mso_id"
                      )
                  v-row
                    v-col
                      v-btn.mb-5(
                        color="secondary"
                        type="submit"
                        block
                        height="50px"
                        :disabled="!valid || !isDirty"
                      ) {{ verbage }} User
                      v-btn(
                        block
                        @click="cancel"
                      ) Cancel
</template>

<script>
import { FeathersVuexFormWrapper, makeGetMixin } from 'feathers-vuex'
import validations from '@/mixins/validationRulesMixin'
import MsosAutocomplete from '@/components/MsosAutocomplete.vue'

export default {
  name: 'EditUser',
  components: {
    FeathersVuexFormWrapper,
    MsosAutocomplete
  },
  mixins: [
    validations,
    makeGetMixin({ service: 'shops', id: 'shopId' }),
    makeGetMixin({ service: 'regions', id: 'regionId' })
  ],
  computed: {
    id () {
      return this.$route.params.userId
    },
    shopId () {
      return this.$route.query.shopId
    },
    regionId () {
      return this.$route.query.regionId
    },
    verbage () {
      return this.id === 'new' ? 'Add' : 'Edit'
    },
    isEdit () {
      return this.id !== 'new'
    },
    isNew () {
      return this.id === 'new'
    },
    currentUser () {
      return this.$store.getters['auth/user']
    },
    // Returns a new User if the route `id` is 'new', or returns an existing User.
    item () {
      const { User } = this.$FeathersVuex.api

      const newUser = {}

      if (this.regionId) {
        newUser.region_id = this.regionId
      }

      if (this.shopId) {
        newUser.shop_id = this.shopId
      }

      return this.id === 'new' ? new User(newUser) : User.getFromStore(this.id)
    },
    availableRoles () {
      if ((this.regionId && this.item.region_id) || (!this.regionId && this.item.region_id) || this.item.permissions === 'REGION_ADMIN') {
        return [
          {
            text: 'Region Admin',
            value: 'REGION_ADMIN'
          }
        ]
      } else if ((this.shopId && this.item.shop_id) || (!this.shopId && this.item.shop_id) || this.item.permissions === 'SHOP_ADMIN' || this.item.permissions === 'SHOP_USER') {
        return [
          {
            text: 'Shop Admin',
            value: 'SHOP_ADMIN'
          },
          {
            text: 'Shop User',
            value: 'SHOP_USER'
          }
        ]
      } else {
        return [
          {
            text: 'Multi-Shop Organization',
            value: 'MSO'
          }
        ]
      }
    }
  },
  watch: {
    id: {
      handler (val) {
        // Early return if the route `:id` is 'new'
        if (val === 'new') {
          return
        }
        const { User } = this.$FeathersVuex.api
        const existingRecord = User.getFromStore(val)

        // If the record doesn't exist, fetch it from the API server
        // The `item` getter will automatically update after the data arrives.
        if (!existingRecord) {
          User.get(val)
        }
      },
      // We want the above handler handler to run immediately when the component is created.
      immediate: true
    }
  },
  methods: {
    handleSaveReponse (savedUser) {
      if (this.shopId || this.regionId) {
        this.$router.push({ name: 'shop-users', params: { shopId: savedUser.shop_id } })
      } else {
        console.log('NO SHOP ID AND NO REGION ID')
        console.log('this', this)
        console.log('savedUser', savedUser)
        this.$router.push({ name: 'users', params: { shopId: this.item.id } })
      }
    },
    cancel () {
      if (!this.isEdit) {
        this.$router.push({ name: 'shop', params: { shopId: this.shopId } })
      } else {
        this.$router.push({ name: 'shop-users', params: { shopId: this.item.shop_id } })
      }
    }
  }
}
</script>
